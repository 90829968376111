
import { defineComponent, ref, computed } from "vue";
import PrintShare from "../store/modules/PrintShare/printShare";
import PatientModule from "../store/modules/Patient/patient";
import UserSettings from "../store/modules/User/user";
export default defineComponent({
  name: "PrePublishDialog",
  setup () {
    const isDialogVisible = computed(() => PrintShare.showPrePublishDialog);
    const isFormValid = ref(false);
    const sendPrintOrder = (): void => {
      PrintShare.setShowPrePublishDialog(false);
      PrintShare.printOrShareMaterial({ triggerPrePublishNotification: false });
    };
    const discard = (): void => {
      PrintShare.setShowPrePublishDialog(false);
    };
    const patientName = computed(() => {
      return PatientModule.getPatient.DisplayName;
    });
    const patientAddress = computed(() => {
      return PatientModule.getAddress;
    });
    const printSend = computed(() => {
      return UserSettings?.displaySettings?.labels?.publishDialogButtonLabel ?? "Send";
    });
    const printTitle = computed(() => {
      return UserSettings?.displaySettings?.labels?.cartPublishButtonLabel ?? "Send Print Order";
    });
    const printNote = computed(() => {
      return UserSettings?.displaySettings?.modals?.prePublishModal?.note ??
      "Items are ready to be submitted for professional printing and mailed to:";
    });
    const printSuccess = computed(() => {
      return UserSettings?.displaySettings?.modals?.prePublishModal?.successMessage ??
        "Published successfully";
    });
    return { discard, isDialogVisible, isFormValid, sendPrintOrder, patientName, patientAddress, printNote, printSend, printSuccess, printTitle };
  },
});
